import React from 'react'
import styles from './HomepageImage.module.css'

const cloudName = 'sublimate'
const linkBase = 'https://res.cloudinary.com/' + cloudName + '/image/upload/'

function convertFormat (filename, extension) {
  if (!filename) return ''
  let splitName = filename.split('.')
  splitName.pop()
  return splitName.join('.') + '.' + extension
}

export default function ({image, title}) {
  return (
    <div className={styles.homepageImage}>
      <div
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: `url(${linkBase + 'c_fill,e_blur:300,q_10,w_1200/' + image})`
        }}
      >
        <picture className={styles.image}>
          <source
            type="image/webp"
            srcSet={linkBase + 'w_1200/' + convertFormat(image, 'webp')}
          />
          <source
            type="image/jpeg"
            srcSet={linkBase + 'w_1200/' + image}
          />
          <img
            src={linkBase + 'w_1200/' + image}
            alt={title}
          />
        </picture>
      </div>
    </div>
  )
}
