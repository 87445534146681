import React from 'react'
import Layout from '../components/Layout'
import HomepageImage from '../components/HomepageImage'
import { graphql } from 'gatsby'

export default class IndexPage extends React.Component {
  render() {
    const images = this.props.data.allMarkdownRemark.edges.map((edge) => {
      return edge.node.frontmatter
    })

    return (
      <Layout>
        <section className="section">
          <HomepageImage key={images[0].name} {...images[0]} />
          <p className="homepage-copy">
            Sublimate and Ruse Laboratories present Smangtasia, an intimate weekender on a 400 acre horse farm in the Catskills with over 36 hours of music curated to showcase the absolute best underground talent this side of the Atlantic has to offer.
          </p><p className="homepage-copy">
            Two days and 2 nights of extravaganza with a pristine soundsystem by SubBass & breathtaking light installations by Kip Davis.
          </p>
          <div className="homepage-video-center">
            <div className="homepage-video-container">
              <iframe
                className="homepage-video"
                src="https://player.vimeo.com/video/348708966?loop=0&color=ffffff&title=1&byline=0&portrait=0"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
              <script src="https://player.vimeo.com/api/player.js"></script>
              <p className="homepage-copy homepage-video-copy">
                <a href="http://anapopescu.com/">
                  Ana Popescu <br /> is bringing art to fully transform the farm for a magical experience.
                </a>
              </p>
              <div style={{clear: 'both'}} />
            </div>
          </div>
          {images.shift() && images.map((image) => <HomepageImage key={image.name} {...image} />)}
          <h3>Smangtasia: it's a place and feeling.<br/><br/>Snag your <a style={{color: "purple"}} href="/tickets">tickets</a> now.</h3>
        </section>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___position], order: ASC }
      filter: { frontmatter: { key: { eq: "featureImage" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            image
            title
            position
          }
        }
      }
    }
  }
`
